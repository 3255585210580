<template>
  <div v-if="data && data.url">
    <div class="wrapper-container ma-auto">
      <div v-if="data.header" class="cms-video-header" v-html="data.header"></div>
      <div
        :class="`wrapper cursor-pointer aspect-ratio-${aspectRatio}`"
        :style="bgStyleComputed"
        @click="playVideo()"
      >
        <div v-if="bgStyleComputed && data.showPlayButton" class="play-btn">
          <v-img src="/play-button.png"></v-img>
        </div>
        <div class="iframe">
          <iframe
            v-if="
              (showThumbnail && play && thumbnailURL) || (!thumbnailURL && !play) || !showThumbnail
            "
            :src="src"
            width="100%"
            height="100%"
            frameborder="0"
            allowfullscreen
            allow="autoplay"
          ></iframe>
        </div>
      </div>
      <div v-if="data.footer" class="cms-video-footer" v-html="data.footer"></div>
    </div>
  </div>
</template>

<script>
import Base from './Base.vue';
import { iframeURL, parseSite } from '@/utils/vimeo';
export default {
  extends: Base,
  props: {
    showThumbnail: {
      type: Boolean,
      default: true
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    thumbnail: {
      type: String,
      default: ''
    },
    aspectRatio: {
      type: String,
      default: '16-9'
    }
  },
  data() {
    return {
      play: false
    };
  },
  computed: {
    src() {
      const site = parseSite(this.data.url);
      const properties = {};
      if (site === 'vimeo') {
        properties.title = 0;
        properties.byline = 0;
        properties.portrait = 0;
        properties.transparent = 1;
        properties.autoplay = !!this.thumbnailURL;
        properties.loop = 1;
        properties.autopause = 0;
      } else if (site === 'loom') {
        properties.autoplay = !!this.thumbnailURL;
      }

      const query = Object.keys(properties).reduce((prev, key) => {
        let res = prev || '';
        if (res) {
          res += '&';
        }
        res += `${key}=${properties[key]}`;
        return res;
      }, '');

      try {
        const url = iframeURL(this.data.url);
        return url + '&' + query;
      } catch (error) {
        return this.data.url;
      }
    },
    thumbnailURL() {
      if (this.data.image && this.data.image.url) {
        return this.data.image.url;
      }
      return null;
    },
    bgStyleComputed() {
      if (this.play || !this.showThumbnail) {
        return '';
      }
      return `
            background-image: url(${this.thumbnail || this.thumbnailURL});
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            cursor: pointer;
      `;
    }
  },
  methods: {
    iframeURL,
    playVideo() {
      this.play = true;
    }
  }
};
</script>

<style lang="scss">
.wrapper-container {
  height: auto;
}

.wrapper {
  position: relative;
  padding-top: 25px;
  height: 0;
}

.wrapper:active {
  cursor: pointer;
}

.wrapper iframe,
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.aspect-ratio-16-9 {
  padding-bottom: 56.25%;
}

.aspect-ratio-4-3 {
  padding-bottom: 75%;
}

.play-btn {
  $button-size: 96px;
  position: absolute;

  width: $button-size;
  height: $button-size;

  margin-left: -$button-size/2;
  margin-top: -$button-size/2;

  @media (max-width: 600px) {
    width: $button-size/2 + 10;
    height: $button-size/2 + 10;

    margin-left: -$button-size/4 + 5;
    margin-top: -$button-size/4 + 5;
  }

  left: 50%;
  top: 50%;
}
</style>
