const vimeoURLFormats = () => {
  return [
    /http?s:\/\/vimeo.com\/(\w+)\/(\w+)$/gi,
    /http?s:\/\/vimeo.com\/(\w+)$/gi,
    /http?s:\/\/player.vimeo.com\/video\/(\w+)(\?h=(\w+))?$/gi
  ];
};

const vimeoURLRule = [
  v => !!vimeoURLFormats().some(fmt => fmt.test(v)) || 'Enter a valid Vimeo url'
];

const validateVimeoURL = url => {
  return vimeoURLFormats().some(fmt => fmt.test(url));
};

const parseVimeoURL = url => {
  const res = { video: '', hash: null };

  vimeoURLFormats().forEach(fmt => {
    const match = fmt.exec(url);
    if (match) {
      if (match.length === 2) {
        res.video = match[1];
      } else if (match.length === 3) {
        res.video = match[1];
        res.hash = match[2];
      } else if (match.length === 4) {
        res.video = match[1];
        res.hash = match[3];
      }
    }
  });

  return res;
};

const parseSite = url => {
  const parsed = new URL(url);
  const hostname = parsed.hostname.toLowerCase();
  if (hostname.includes('loom')) {
    return 'loom';
  } else if (hostname.includes('vimeo')) {
    return 'vimeo';
  } else {
    return '';
  }
};

const iframeURL = url => {
  const site = parseSite(url);
  let src = '';
  if (site === 'vimeo') {
    const vimeo = parseVimeoURL(url);
    src = `https://player.vimeo.com/video/${vimeo.video}?`;
    if (vimeo.hash) {
      src = `${src}h=${vimeo.hash}`;
    }
  } else if (site === 'loom') {
    const re = /http?s:\/\/(www.)?loom.com\/(embed|share)\/(\w+)/gi;
    const videoKey = re.exec(url)[3];
    return `https://www.loom.com/embed/${videoKey}?`;
  }
  return src;
};

export { parseVimeoURL, validateVimeoURL, vimeoURLRule, iframeURL, parseSite };
